<template>
  <div class="loginRecordsLog">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :isOperateColumn="false"
      :searchParam.sync="searchParam"
    >
      <template #searchSlot>
        <v-input clearable label="姓名" v-model="searchParam.userName" />
        <v-input clearable label="手机号" v-model="searchParam.mobileNum" />
        <v-input clearable label="用户名" v-model="searchParam.loginName" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { getLogInfoUrl } from "./api.js";
export default {
  name: "loginRecordsLog",
  data() {
    return {
      searchParam: {},
      tableUrl: getLogInfoUrl,
      headers: [
        {
          prop: "userName",
          label: "用户姓名",
        },
        {
          prop: "mobileNum",
          label: "手机号",
        },
        {
          prop: "loginName",
          label: "登录用户名",
        },
        {
          prop: "userIp",
          label: "登录者IP",
        },
        {
          prop: "createTime",
          label: "登录时间",
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.loginRecordsLog {
  box-sizing: border-box;
  height: 100%;
}
</style>
